// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

$project-font-family: Roboto;
$font-family: #{$project-font-family}, -apple-system, BlinkMacSystemFont,
  Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
  sans-serif;

// https://www.figma.com/file/wECDRuIqmlBltXJTE9bOEn/_PS%3A-library?node-id=3538%3A19241
// _PS: library -> Spacing
$space-unit: 4;

$spacers: (
  auto: auto,
  0: 0,
  1: $space-unit * 1,
  2: $space-unit * 2,
  3: $space-unit * 3,
  4: $space-unit * 4,
  5: $space-unit * 5,
  6: $space-unit * 6,
  7: $space-unit * 7,
  8: $space-unit * 8,
  9: $space-unit * 9,
  10: $space-unit * 10,
  11: $space-unit * 11,
  12: $space-unit * 12,
);

// $grid-breakpoints (используются только в сетке):
//   - sm – от 0 до 414.98
//   - md – от 415 до 768.98
//   - lg – от 769 до 1024.98
//   - xl – от 1025
// https://docs.litres.ru/x/s6HyAw
// https://www.figma.com/file/wECDRuIqmlBltXJTE9bOEn/_PS%3A-library?node-id=3501%3A108476
// breakpoint -md - был 415рх но пришлось его немного сдвинуть из-за карты лицензии, наблюдаем общее поведение
$grid-breakpoints: (
  sm: 0,
  md: 415px,
  lg: 769px,
  xl: 1025px,
) !default;
$grid-columns: 12 !default;
$grid-row-columns: 6 !default;
$gutters: (
  sm: #{($space-unit * 2)}px,
  md: #{($space-unit * 2)}px,
  lg: #{($space-unit * 4)}px,
  xl: #{($space-unit * 4)}px,
) !default;

// Переменные для медиа-запросов
$md: 414px;
$lg: 768px;
$xl: 1024px;

$fix-container-width: 1040px;
