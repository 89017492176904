@import 'src/styles/variables';

.container {
  position: relative;

  z-index: 1;

  max-width: $fix-container-width;
  margin: 0 auto;

  &.last {
    margin-bottom: 344px;
  }
}

.block {
  padding: 112px 0;

  @media (max-width: $lg) {
    padding: 64px 0;
  }
}

.successStoriesBlock {
  padding: 112px 0;

  @media (max-width: $lg) {
    padding: 64px 0;
  }
}

.contestsBlock {
  padding: 64px 0 0;

  @media (max-width: $lg) {
    padding: 56px 0;
  }
  @media (max-width: $md) {
    padding: 32px 0;
  }
}
