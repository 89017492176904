.wrapper {
  position: absolute;
  top: 48px;
  left: 12px;
}

.red {
  color: var(--palettes-red-100);
}

.orange {
  color: var(--palettes-orange-100);
}

.green {
  color: var(--palettes-green-100);
}
