.root {
  margin: 0;
  padding: 0;

  list-style: none;
}

.listItem {
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.link {
  display: block;

  color: var(--text-base);

  font-size: 16px;
  line-height: 22px;
  text-decoration: none;

  transition: opacity 0.25s;
  &:hover {
    opacity: 0.8;
  }
}
