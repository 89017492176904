@import 'src/styles/variables';

.wrapper {
  position: relative;

  padding: 80px 0 112px;

  text-align: center;

  @media (max-width: $xl) {
    padding: 88px 0 56px;
  }

  @media (max-width: $lg) {
    padding: 32px 0 56px;
  }

  @media (max-width: $md) {
    padding: 40px 0 56px;
  }
}

.textBlock {
  font-weight: 700;
  font-size: 72px;

  font-style: normal;
  line-height: 76px;

  & span {
    color: var(--palettes-orange-100);
  }

  @media (max-width: $lg) {
    font-size: 40px;
    line-height: 44px;
  }

  @media (max-width: $md) {
    font-size: 32px;
    line-height: 40px;
  }
}

.typedWord {
  color: var(--palettes-orange-100);
}

.word {
  display: inline;
}

.button {
  margin-top: 32px;

  @media (max-width: $lg) {
    margin-top: 24px;
  }
}

.icon {
  position: absolute;

  transition: all 0.3s ease-out;

  @media (max-width: $xl) {
    display: none;
  }
}

.iconHeadphones {
  top: 0;
  right: -12px;
}

.iconHeadphonesRotate {
  transform: rotate(30deg);
}

.iconBrush {
  top: 120px;
  right: 100px;
}

.iconBook {
  bottom: 12px;
  left: 124px;
}

.iconPrint {
  top: -32px;
  left: -4px;
}

.iconLetter {
  right: 0;
  bottom: 20px;
}

.iconLetterRotate {
  transform: rotate(-15deg);
}

.iconPicture {
  top: 204px;
  left: -104px;
}
