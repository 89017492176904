.socialList {
  display: grid;
  grid-template-columns: repeat(4, 32px);
  gap: 9px;
  margin: 0 0 32px;
  padding: 0;

  list-style: none;
}

.socialListLink {
  display: block;
  width: 32px;
  height: 32px;

  transition: opacity 0.25s;
  &:hover {
    opacity: 0.8;
  }
}

.contactLink {
  display: block;
  margin-bottom: 8px;

  color: var(--palettes-gray-190);
  text-decoration: none;

  transition: opacity 0.25s;
  &:hover {
    opacity: 0.8;
  }
}
