@import 'src/styles/variables';

.container {
  display: flex;

  height: 386px;

  @media (max-width: $xl) {
    flex-direction: column;

    height: 100%;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: $xl) {
    align-items: center;
    margin-top: 190px;
  }
}

.title {

  width: 420px;

  font-size: 48px;
  line-height: 56px;
  letter-spacing: 0.25px;

  @media (max-width: $xl) {
    width: 100%;

    font-size: 32px;
    line-height: 42px;
    text-align: center;
  }
}

.text {

  width: 471px;
  margin-top: 24px;

  @media (max-width: $xl) {
    width: 95%;
    max-width: 506px;

    text-align: center;
  }
}

.button {

  width: 206px;
  margin-top: 32px;
}

.imageWrapper {
  @media (max-width: $xl) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.bookImage {
  position: absolute;

  margin-left: 20px;
  
  transition: transform 0.3s ease;

  @media (max-width: $xl) {
    top: 32px;

    margin-left: 0;
  }
}

.phoneImage {
  position: absolute;

  margin: 12px 0 0 125px;

  transition: transform 0.3s ease;

  @media (max-width: $xl) {
    top: 5px;

    margin-left: 0;
  }
}
