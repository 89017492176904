.divider {
  --divider-color: var(--palettes-gray-40);
  --divider-height: 1px;
  --divider-width: 100%;
  --divider-space-before: unset;
  --divider-space-after: unset;

  width: var(--divider-width);
  height: var(--divider-height);

  margin-block-start: var(--divider-space-before);
  margin-block-end: var(--divider-space-after);

  background-color: var(--divider-color);
}
