@import 'src/styles/variables';

.root {
  padding: 54px 0;

  background-color: var(--palettes-blue-20);

  @media (min-width: ($xl + 1px)) and (max-width: ($fix-container-width - 1px)) {
    margin: 0 -32px;
  }
  @media (min-width: ($md + 1px)) and (max-width: $xl) {
    margin: 0 -24px;
  }
  @media (max-width: $md) {
    margin: 0 -16px;
  }

  @media (max-width: $xl) {
    padding: 54px 24px;
  }
  @media (max-width: $md) {
    padding: 54px 16px;
  }
}

.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: $fix-container-width;
  margin: 0 auto;

  @media (max-width: $xl) {
    width: 100%;
  }
  @media (max-width: $lg) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-start;
  }
}

.content {
  width: 578px;

  @media (max-width: $xl) {
    width: 52%;
  }
  @media (max-width: $lg) {
    width: 100%;
  }
}

.title {
  width: 374px;
  margin-bottom: 24px;

  color: var(--palettes-orange-100);

  @media (max-width: $xl) {
    width: 100%;
  }
  @media (max-width: $lg) {
    font-size: 38px;
    line-height: 44px;
    letter-spacing: 0.25px;
  }
  @media (max-width: $md) {
    font-size: 28px;
    line-height: 36px;
  }
}

.subtitle {
  width: 480px;
  margin-bottom: 24px;

  @media (max-width: $xl) {
    width: 100%;
  }
}

.accordeon {
  margin-bottom: 32px;
}

.accordeonHeader {
  justify-content: space-between;

  color: var(--text-base);
  font-weight: 700;

  font-size: 16px;
  line-height: 22px;

  svg:last-child {
    margin-left: auto;
  }

  @media (max-width: $xl) {
    align-items: center;
  }
  @media (max-width: $md) {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.25px;
  }
}

.accordeonBody {
  margin-top: 24px;
  margin-left: 0;
}

.accordeonBlock {
  color: var(--text-base) !important;
  font-size: 16px !important;
  line-height: 22px !important;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
  ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 16px;
  }

  @media (max-width: $md) {
    font-size: 14px !important;
    line-height: 18px !important;
    letter-spacing: 0.25px;

    ul {
      padding-left: 14px;
    }
  }
}

.accordeonDivider {
  --divider-color: var(--palettes-blue-40);
}

.button {
  width: 210px;

  @media (max-width: $md) {
    width: 100%;
  }
}

.cupImage {
  @media (max-width: $xl) {
    width: 42%;

    > div {
      width: 100% !important;
      height: 100% !important;
    }
  }
  @media (max-width: $lg) {
    width: 130px;
    margin-bottom: 24px;
  }
}
