@import 'src/styles/variables';

.modalCustomStyle {
  width: 640px;
  height: 400px;

  @media (max-width: $md) {
    height: 425px;
    --modal-header-width-mobile: 275px;
    --modal-header-height-mobile: 65px;
  }
}

.wrapperOffer {
  height: 210px;
  margin: 24px;
  padding: 5px;
  overflow: scroll;
}

.goToFormText {
  margin: 24px;
}

.offerPageBackground {
  padding: 8px;

  background-color: var(--text-secondary);
}

.footerModal {
  position: absolute;
  bottom: 0;
}
