.wrapper {
  position: fixed;
  right: 5px;
  bottom: 20px;
  z-index: 99999;
  @media (max-width: 524px) {
    width: 100%;
  }
}

.banner {
  position: absolute;

  right: 5px;
  bottom: 0;

  display: flex;

  align-items: center;

  width: 500px;
  padding: 16px 10px 16px 20px;
  border-radius: 3px;

  background-color: var(--palettes-gray-190);

  @media (max-width: 524px) {
    flex-direction: column;
    width: 95%;
  }
}

.text {
  color: var(--palettes-white-100);

  font-size: 14px;
  line-height: 18px;
}

.link {
  color: #106edc;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;

  cursor: pointer;
}

.readMore {
  &:hover {
    color: var(--palettes-orange-100);
  }
}

.button {
  width: 80px;
  height: 30px;
  padding: 8px 10px 10px;
  border: 1px solid #ebebeb;
  border-radius: 8px;

  color: #ebebeb;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;

  background-color: var(--palettes-gray-190);
  &:hover {
    border-color: var(--palettes-gray-60);

    color:  var(--palettes-gray-60);

    transition: .5s;
  }

  @media (max-width: 524px) {
    margin-top: 10px;
  }
}
