@import 'src/styles/variables';

.blocks {
  display: flex;
  justify-content: space-between;

  @media (min-width: ($lg + 1px)) and (max-width: $xl) {
    flex-wrap: wrap; 
  }

  @media (max-width: $lg) {
    flex-direction: column;
  }
}

.block {
  width: calc(33% - 16px);
  height: 380px;
  padding: 32px;
  border-radius: 24px;

  hyphens: none;

  background-color: var(--palettes-blue-10);

  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: var(--palettes-blue-40);
  }

  @media (min-width: ($lg + 1px)) and (max-width: $xl) {
    width: calc(50% - 16px);

    &:last-child {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: auto;
      margin-top: 32px;

      .info {
        width: calc(100% - 128px);
      }
    }
  }

  @media (max-width: $lg) {
    width: 100%;
    height: auto;
    margin-bottom: 32px;
    padding-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.title {
  margin: 16px 0;
}
