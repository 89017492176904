@import 'src/styles/variables';

.root {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.juryCard {
  > span {
    display: block !important;
  }

  @media (max-width: $md) {
    padding: 0 7px;
  }
}

.juryImage {
  width: 80px;
  height: 80px;

  @media (max-width: $md) {
    width: 110px !important;
    height: 110px !important;
  }
}

.juryName {
  width: min-content;
  margin: 16px auto 0 !important;

  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.4px;
  text-align: center;
}
