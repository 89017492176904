@import 'src/styles/variables';

.root {
  position: relative; // для анимации блока перед футером

  grid-area: footer;
  width: 100%;
  padding: 48px 0;

  background-color: var(--palettes-gray-5);

  @media print {
    display: none;
  }
  @media (max-width: $xl) {
    padding: 48px 32px;
  }
  @media (max-width: $lg) {
    padding: 48px 16px;
  }
}

.innerWrapper {
  display: flex;

  width: 100%;
  max-width: $fix-container-width;
  margin: 0 auto;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--palettes-gray-40);

  @media (max-width: $lg) {
    display: block;
  }
}

.social {
  flex-grow: 0;
  flex-shrink: 0;
  width: 200px;
  margin-right: 56px;

  @media (max-width: $lg) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 32px;
  }
}

.menusWrapper {
  display: flex;
  flex-grow: 1;

  @media (max-width: $xl) {
    flex-direction: column;
  }
  @media (max-width: $lg) {
    display: block;
  }
}

.section {
  flex-grow: 0;
  flex-shrink: 0;

  &:nth-child(1) {
    width: 376px;
    margin-right: 32px;
  }
  &:nth-child(2) {
    width: 376px;
  }

  @media (max-width: $xl) {
    &:nth-child(1) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 32px;
    }
    &:nth-child(2) {
      width: 100%;
    }
  }
}
