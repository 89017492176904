@import 'src/styles/variables';

.header {
  display: flex;
  align-items: center;
  min-height: 24px;

  outline: none !important;

  transition: 0.2s;

  appearance: none !important;

  &:hover {
    opacity: 0.6;
  }

  &:active {
    opacity: 0.3;
  }

  @media (max-width: $xl) {
    align-items: flex-start;
  }
  svg {
    display: block;
    flex-shrink: 0;
  }
}

.headerIcon {
  margin-right: 8px;
}

.arrowRight {
  margin-left: 8px;
}

.arrowLeft {
  margin-right: 8px;
}

.content {
  margin: 8px 0 0 24px;
}
