@import 'src/styles/variables';

.wrapper {
  @media (max-width: $fix-container-width) {
    span {
      display: flex;
      align-items: baseline;
    }
  }
}

.collapse {
  color: var(--palettes-gray-190);
  font-size: 20px;
  line-height: 26px;
}

.collapseBody {
  margin-top: 16px;
}

.block {
  margin-top: 0;

  color: var(--palettes-gray-160);
}

.divider {
  margin: 24px 0;
}
