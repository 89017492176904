.root {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 1rem;
}

.linkRestorePassword {
  color: var(--palettes-blue-100);

  cursor: pointer;
}

.withError {
  margin-bottom: 10px;

  color: var(--aliases-error);
}
