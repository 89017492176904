.root {
  padding: 1rem;
}

.footer {
  max-width: 340px;
  margin-top: 20px;
}

.withError {
  color: var(--palettes-red-90);
}

.wrapperInput {
  margin: 0 0 20px 0;
}

.passwordWrapper {
  position: relative;
}

.explanationContainer {
  margin-top: 24px;
}
