.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.iconContainer {
  opacity: 1;
}

.message {
  margin-top: 10px;
}
