@import 'src/styles/variables';

.root {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
  max-width: $fix-container-width;
  margin: 0 auto;
  padding: 24px 0 0;

  list-style: none;

  @media (max-width: $lg) {
    flex-direction: column;
    gap: 16px;
  }
}

.link {
  display: block;

  color: var(--palettes-gray-120);
  text-decoration: none;

  transition: opacity 0.25s;
  &:hover {
    opacity: 0.8;
  }

  & p {
    color: inherit;
  }
}
