.root {
  padding: 1rem;
}

.footer {
  max-width: 340px;
  margin-top: 20px;

  text-align: center;
}
