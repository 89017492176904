.alert {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 13px 0 13px 13px;
  border: var(--palettes-blue-40) 1px solid;
  border-radius: 16px;

  white-space: pre-wrap;

  &.small {
    padding: 8px 0 7px 8px;
    border-radius: 12px;
  }
  &.info {
    border: var(--palettes-blue-40) 1px solid;

    background-color: var(--palettes-blue-10);
  }
  &.error {
    border: var(--palettes-red-30) 1px solid;

    background-color: var(--palettes-red-5);
  }
  &.success {
    border: var(--palettes-green-30) 1px solid;

    background-color: var(--palettes-green-5);
  }
}

.alertIcon {
  margin: 0 1px;

  &.small {
    margin: -1px 1px 0 3px;
  }
  &.info {
    color: var(--palettes-blue-100);
  }
  &.error {
    color: var(--palettes-red-100);
  }
  &.success {
    color: var(--palettes-green-100);
  }
}

.alertIconAndTextBlock {
  display: flex;
  align-content: center;
  align-items: center;

  padding-right: 12px;
}

.title {
  padding-top: 2px;
  padding-left: 7px;
}

.textInformationBlock {
  padding-top: 2px;
  padding-left: 7px;

  color: var(--palettes-gray-190) !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px !important;
}

.roundAlertIcon {
  display: flex;
  align-self: flex-start;
}

.button {
  max-height: 24px;
  margin-right: 12px;
}

.closeIcon {
  margin-right: 16px;
  padding: 0;
  border: none;

  background-color: transparent;

  &:hover {
    cursor: pointer;
  }
}
