@import 'src/styles/variables';

.blocks {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media (max-width: $xl) {
    grid-template-columns: repeat(auto-fit, minmax(268px, 1fr));
  }
}

.block {
  width: 308px;
  margin: 0 auto 56px;

  @media (max-width: $xl) {
    width: calc(100% - 28px);
  }

  @media (max-width: $lg) {
    width: 100%;
    margin: 0 auto 32px;
  }
}

.text {
  position: relative;

  width: 100%;
  min-width: 268px;
  height: 236px;
  margin-bottom: 24px;
  padding: 48px;
  border-radius: 24px 24px 24px 0;

  background-color: var(--palettes-orange-10);
  cursor: pointer;

  transition: background-color 0.3s ease-out, transform 0.3s ease-out;

  &::before {
    position: absolute;
    bottom: -24px;
    left: 0;

    width: 24px;
    height: 24px;

    background-color: var(--palettes-orange-10);

    transition: background-color 0.3s ease-out;

    content: '';
  }

  &::after {
    position: absolute;
    bottom: -24px;
    left: 0;

    width: 24px;
    height: 24px;
    border-radius: 24px 0 0 0;

    background-color: var(--palettes-white-100);

    content: '';
  }

  .icon {
    position: absolute;
    top: 24px;
    right: 24px;

    transform: translateY(12px) translateX(-12px);
    opacity: 0;

    transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  }
}

.textHover {
  @media (min-width: $lg) {
    &:hover {
      background-color: var(--palettes-orange-20);
      transform: translateY(-5px);

      .icon {
        transform: translateY(0) translateX(0);
        opacity: 1
      }

      &::before {
        background-color: var(--palettes-orange-20);
      }
    }
  }
}

.about {
  display: flex;
  padding: 8px;
}

.info {
  margin-left: 16px;
}

.genre {
  color: var(--palettes-gray-100)
}

.invite {
  height: 236px;
  padding: 52px 48px;
  border: 1px solid var(--palettes-blue-20);
  border-radius: 24px;

  text-align: center;

  transition: background-color 0.4s ease-out;

  &:hover {
    background-color: var(--palettes-blue-10);
  }
}

.inviteText {
  width: 176px;
  margin: 0 auto 24px;
}

.modal {
  width: 960px;
  max-width: 960px;
  max-height: calc(100vh - 112px);
  padding-bottom: 32px;

  @media (max-width: $lg) {
    max-height: 100vh;
    border-radius: 0;
  }
}

.modalWrapper {  
  max-height: calc(100vh - 112px);
  padding: 16px 192px;
  overflow-y: scroll;
  
  @media (max-width: $xl) {
    padding: 16px 121px;
  }

  @media (max-width: $lg) {
    padding: 16px 32px;
  }
}

.modalTitle {
  margin: 24px 0;
}

.modalText {
  margin-top: 16px;
}

.modalQuote {
  position: relative;

  margin: 24px 0;
  padding: 24px;

  color: var(--palettes-gray-100);
}

.modalQuoteText {
  color: var(--palettes-gray-100);
}

.modalQuoteTop {
  position: absolute;
  top: 5px;
  left: 6px;
}

.modalQuoteBottom {
  position: absolute;
  right: 6px;
  bottom: 20px;
}

.modalInvite {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
  padding: 24px;
  border-radius: 16px;

  background-color: var(--palettes-blue-10);

  @media (max-width: $md) {
    flex-direction: column;
  }
}

.modalInviteText {
  max-width: 280px;
  margin-right: 24px;

  @media (max-width: $md) {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 24px;
  }
}

.modalButton {
  width: 256px;

  @media (max-width: $xl) {
    width: 220px;
  }

  @media (max-width: $md) {
    width: 100%;
  }
}
