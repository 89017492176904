.titles {
  margin-bottom: 56px;

  text-align: center;
}

.title {
  margin-bottom: 16px;
}

.subtitle {
  max-width: 764px;
  margin: 0 auto;

  color: var(--palettes-gray-160);
}
