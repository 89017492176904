.container {
  position: sticky;
  top: 24px;

  z-index: 1;

  margin-bottom: 16px;
}

.link {
  color: var(--aliases-link);

  cursor: pointer;
}
