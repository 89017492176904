@import 'src/styles/variables';

.title {
  text-align: center;
}

.blocksWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-top: 48px;

  @media (max-width: $xl) {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
}

.block {
  padding: 30px 32px;
  border-radius: 24px;

  background-color: var(--palettes-blue-10);
}

.blockTitle {
  margin-top: 20px;
}

.text {
  margin-top: 8px;
}
