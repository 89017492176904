@import 'src/styles/variables';

.floatLabel {
  position: absolute;
  top: 13px;

  width: 96.5%;
  height: 20px;
  margin: 0 6px;
  padding-left: 12px;
  border-radius: 4px;

  color: var(--palettes-gray-120);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.25px;

  transition: 0.2s;

  pointer-events: none;

  @media (max-width: $lg) {
    width: calc(100% - 32px);
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @media (max-width: 360px) {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  @media (max-width: 360px) {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.backLabelColor {
  background-color: var(--text-secondary);
}

.activeLabel {
  top: 2px;

  padding-top: 4px;

  font-size: 12px;
  line-height: 14px;
}

.input {
  width: 100%;
  height: 44px;
  margin: 0;
  padding: 20px 16px 6px;
  border: 2px solid var(--palettes-gray-40);
  border-radius: 8px;

  font-size: 14px;
  font-family: Roboto, sans-serif;
  line-height: 18px;
  letter-spacing: 0.25px;

  outline: none;

  transition: 0.2s;

  appearance: none;

  &:disabled {
    color: var(--palettes-gray-100);

    background-color: var(--palettes-gray-20);
    opacity: 1;
  }

  &:disabled + .floatLabel {
    background-color: var(--palettes-gray-20);
  }

  // Закрашивание input для ios при дизейбле
  @supports (-webkit-appearance: none) {
    &:disabled {
      background-color: var(--palettes-gray-20);
    }
  }

  &::placeholder {
    color: var(--palettes-gray-40);
    letter-spacing: 0.25px;

    opacity: 0;

    transition: opacity .3s ease-out;
  }

  &:hover:enabled {
    border: 2px solid var(--palettes-blue-60);
  }

  &:focus:enabled {
    border: 2px solid var(--palettes-blue-100);
  }

  &:focus::placeholder {
    opacity: 1;
  }

  &:focus + .floatLabel {
    top: 2px;

    padding-top: 4px;

    font-size: 12px;
    line-height: 14px;
  }

  // TODO: без этого костыля не отрабатывает активность лейбла в инпуте, когда хром автозаполнил поля и данных в стейте нет, а в инпуте есть,  это единственное свойство которое сообщает браузер о автозаполнении полей из соображений безопасности кражи данных! Штука с модалками эта не нужна, так как там есть фокус, а на странице пока не тапнешь по станице, лейбл не понимает что ему нужно подпрыгнуть!

  &:-webkit-autofill + .floatLabel {
    top: 2px;

    padding-top: 4px;

    font-size: 12px;
    line-height: 14px;
  }

  // Скрытие стрелок для input type="number"
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &.noLabel {
    padding-top: 11px;
    padding-bottom: 11px;

    &::placeholder {
      opacity: 1;
    }
  }
  &.noLabelTextarea {
    padding-top: 6px;
    padding-bottom: 6px;

    &::placeholder {
      opacity: 1;
    }
  }
}

.truValue {
  top: 2px;

  padding-top: 4px;

  font-size: 12px;
  line-height: 14px;
}

.label {
  position: relative;

  display: block;
}

.labelWithError {
  margin-bottom: 16px;
}

.wrapperInfo {
  display: flex;
  justify-content: space-between;
}

.helpText {
  left: 16px;

  margin: 2px 0 4px 0;

  color: var(--palettes-gray-160);
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.4px;
  white-space: break-spaces;
}

.lengthCounter {
  grid-column-start: 3;
  margin-top: 4px;
  padding-left: 8px;

  color: var(--palettes-gray-160);
  font-size: 12px;
  line-height: 14px;
  text-align: right;
}

.withError {
  color: var(--palettes-red-100);
}

.withErrorBorder {
  border: 2px solid var(--palettes-red-100);
}

.textAriaSize {
  display: block;

  min-width: 255px;
  min-height: 166px;
  max-height: 166px;
}

.resizable {
  resize: none;
}

.showPasswordWrapper {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: var(--above-content);

  cursor: pointer;
}
