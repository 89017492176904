@import 'src/styles/variables';

.blocks {
  display: flex;
  justify-content: space-between;

  @media (max-width: $lg) {
    flex-direction: column;
    align-items: center;
  }
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 308px;
  margin-right: 36px;

  text-align: center;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: $lg) {
    margin-right: 0;
    margin-bottom: 56px;
    padding: 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.title {
  width: 192px;
  margin-top: 32px;
}

.subtitle {
  margin-top: 12px;

  color: var(--palettes-gray-160);
}
