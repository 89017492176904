@import 'src/styles/variables';

.overlay {
  --modal-window-border-radius: 12px;
  --modal-window-background: var(--palettes-white-100);
  --modal-overlay-background: rgba(0, 0, 0, 0.39);
  --modal-content-overflow: auto;
  --modal-min-width: 320px;
  --modal-width: 368px;
  --modal-min-height: 124px;
  --modal-z-index: 10;
  --modal-actions-gap: 16px;
  --modal-close-button-size: 32px;
  --modal-header-width-mobile: 320px;
  --modal-header-height-mobile: 48px;

  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--modal-z-index);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  background: var(--modal-overlay-background);
}

.modal {
  position: relative;

  display: flex;
  flex-direction: column;

  width: var(--modal-width);
  min-width: var(--modal-min-width);
  height: var(--modal-height);
  min-height: var(--modal-min-height);
  margin: 0 auto;
  padding: 0;

  border-radius: var(--modal-window-border-radius);
  overflow: hidden;

  background: var(--modal-window-background);
}

.focusLock {
  height: 100%;
}

.closeButton {
  position: absolute;
  top: 12px;
  right: 16px;
  z-index: var(--modal-z-index);

  display: flex;
  align-items: center;
  justify-content: center;

  width: var(--modal-close-button-size);
  height: var(--modal-close-button-size);
  padding: 0;
  border-color: transparent;
  border-radius: 50%;

  color: var(--palettes-black-100);

  background-color: transparent;
  outline: none;
  cursor: pointer;

  transition: background-color 0.3s ease-out, color 0.3s ease-out;

  &:hover {
    background-color: var(--palettes-blue-20);
  }

  &:active {
    color: var(--palettes-blue-120);
  }
}

.header {
  min-height: var(--modal-header-height);
  padding: 16px 12px;

  background: var(--modal-window-background);

  @media (max-width: $md) {
    min-height: var(--modal-header-height-mobile);
  }
}

.headerTitle {
  font-weight: 700 !important;

  @media (max-width: $md) {
    width: var(--modal-header-width-mobile);
  }
}

.content {
  flex-grow: 1;
  height: 100%;
  padding: 0;
  overflow-y: visible;
}

.contentWrapper {
  padding: var(--modal-window-padding);
}

.footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 12px;

  background: var(--modal-window-background);
}

.footerCaption {
  color: var(--palettes-gray-160);
}

.actionWrapper {
  display: flex;
  gap: var(--modal-actions-gap);
  justify-content: flex-end;
}
